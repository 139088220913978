import React, { useState, useCallback, useRef, useEffect, useContext } from "react";
import { motion, useAnimation } from "framer-motion";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import { alpha } from "@mui/system";
import { useTheme } from '@mui/material/styles';
import { NotInViewContext } from "./AnimateScrollIntoViewport";


const numberOfTexts = 10
const arrAnim = Array(numberOfTexts).fill()
const writeEmoji = "✍️"
const callMeHandEmoji = "🤙"


const AnimateWavingTexts = ({ text = `Napíšte${writeEmoji} nám, zavolajte${callMeHandEmoji} ...`, wrapStyle = {} }) => {
  const [ replay, setReplay ] = useState(true);
  const refAlreadyClicked = useRef(false)

  const isMounted = useRef(false);
  const refWrap = useRef(null)
  const { notInView, isCountedTwice } = useContext(NotInViewContext)
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, [])
  return (
    <Box
      ref={refWrap}
      className="wavingTexts"
      sx={{
        display: 'flex',
        position: 'relative',
        justifyContent: 'flex-start',
        alignItems: 'center',
        transform: [ null, null, 'translateY(150px)' ],
        fontSize: [ 'smaller', 'small' ],
        zIndex: 1,
        // transformOrigin: "0 center",
        transformOrigin: 'left',
        "& button": {
          bgcolor: "primary.dark",
          color: "primary.main",
          zIndex: 1,
        }

      }} >
      {
        arrAnim.map((_, j) => (
          <WavyText refAlreadyClicked={refAlreadyClicked} isInView={!notInView && isCountedTwice} text={text} replay={replay} wrapStyle={wrapStyle} iText={j} key={`w-${j}`} />
        )
        )
      }
      <IconButton aria-label="replay" size="large"
        onClick={() => {
          if (window) {
            refAlreadyClicked.current = true
            setReplay(!replay);
            window.setTimeout(() => isMounted.current && setReplay(true), 12000);
          }
        }}
      >
        <AutorenewIcon />
      </IconButton>
    </Box>
  )
}


const hidden = {
  opacity: 0,
  x: -20,
  y: 20,
  transition: {
    type: "spring",
    damping: 12,
    stiffness: 200
  }
}
const letterBack = {
  opacity: 0.2,
  x: -20,
  y: 20,
  transition: {
    type: "spring",
    damping: 12,
    stiffness: 200
  }
}

const varContainer = {
  hidden: {
    opacity: 0,
    x: 0,
  },
  visible: () => ({
    opacity: 1,
    x: 0,
    rotateZ: "0deg",
    transition: {
      staggerChildren: 1,
    }
  })
};


const WavyText = ({
  refAlreadyClicked,
  isInView,
  text,
  iText,
  replay,
  wrapStyle = {},
  delayLetter = 0.1,
  duration = 0.05,
  ...props
}) => {
  const isMounted = useRef(false);

  const letters = Array.from(text);
  const ctrlCont = useAnimation()
  const ctrlLetter = useAnimation()
  const theme = useTheme()

  const stopAnimation = useCallback(
    () => {
      ctrlLetter.stop()
      ctrlCont.stop()
    },
    [ ctrlLetter, ctrlCont ],
  )
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, [])

  useEffect(() => {
    const canAnimate = isInView || refAlreadyClicked.current
    if (!canAnimate) {
      stopAnimation()
      return
    }

    const seqAnimWaves = async () => {

      isMounted.current && ctrlCont.start("visible")

      if (canAnimate && !replay) {

        isMounted.current && ctrlLetter.start(
          (index) => {
            // const stiffness = 50 - 4 * iText
            // const damping = 0.5 + 0.2 * Math.abs(Math.round(iText / 3 + index / 10, 1))
            const delay = delayLetter + index * delayLetter + iText * delayLetter
            const iProd = index * iText
            return ({
              // opacity: 1,
              y: -2 * iProd,
              x: 1.5 * iProd, // 2 * (index * 5 + iText * 10) + iProd,
              transition: {
                delay: delay,
                duration: 5,
                // type: "spring",
                // damping: damping,
                // stiffness: stiffness,
              }
            })
          })
        isMounted.current && iText === (numberOfTexts - 1) && ctrlLetter.start(
          (index) => ({
            // skewY: 20,
            // scale: 1.6,
            backgroundColor: [ theme.palette.primary.main, theme.palette.error.main ],
            transition: {
              delay: delayLetter * index,
              type: "spring",
              damping: 1,
              stiffness: 100, // 200,
            }
          })
        )
        return
      }



      isMounted.current && canAnimate && replay && await ctrlLetter.start(hidden)

      isMounted.current && await ctrlLetter.start(
        (index) => {
          const stiffness = 50 - 4 * iText
          const damping = 0.5 + 0.2 * Math.abs(Math.round(iText / 3 + index / 10, 1))
          const delay = delayLetter + index * delayLetter + iText * delayLetter
          return ({
            opacity: 1,
            y: 0.6 * index * iText,
            x: 0.8 * ((index * 5 + iText * 10) + index * iText),
            transition: (canAnimate && replay) ? {
              delay: delay,
              type: "spring",
              damping: damping,
              stiffness: stiffness,
            } : { duration: 0 }
          })
        })

      isMounted.current && iText === (numberOfTexts - 1) && ctrlLetter.start(
        (index) => ({
          skewY: -20, // [ null, -20 ],
          scale: 1.6, // [ null, 1.6 ],
          backgroundColor: [ theme.palette.primary.main, theme.palette.error.main ],
          transition: (canAnimate && replay) ? {
            delay: delayLetter * index,
            type: "spring",
            damping: 1,
            stiffness: 100, // 200,
          } : { duration: 0 }
        })
      )

    }
    seqAnimWaves()
    return () => { stopAnimation() }

  }, [ isInView, stopAnimation, replay, ctrlCont, ctrlLetter, delayLetter, iText, theme.palette.error.main, theme.palette.primary.main, refAlreadyClicked ])


  return (
    <motion.p
      style={{
        position: "absolute",
        width: "20px",
        // display: 'inline-flex'
        display: 'inline-grid',
        // overflow: "hidden"
        opacity: isInView ? 0 : 1,
        x: iText * 20,
        rotateZ: `${(360 - iText * 2)}deg`,
      }}
      variants={varContainer}
      initial={false} // {isInView ? "hidden" : "visible"}
      animate={ctrlCont}

      {...props}
    >

      {
        letters.map((letter, index) => {

          const orderIn3 = (index + iText) % 3 // 
          const colorIn3 = orderIn3 === 0 ? theme.palette.error.main : orderIn3 === 1 ? theme.palette.primary.main : theme.palette.success.main
          return (
            <Box
              component={motion.span}
              key={index}
              initial={false}
              custom={index}
              animate={ctrlLetter}
              sx={{
                // x: -20,
                // y: 20,
                y: -2 * index * iText,
                x: 2 * index * iText,
                // x: (index * 5 + iText * 10) + index * iText,
                //   y: -2 * iProd,
                // x: 2 * iProd, 
                opacity: isInView ? 0 : 1,
                backgroundColor: (theme) => alpha(colorIn3, 0.5),
                borderRadius: "25%"
              }}
              whileHover={{
                rotateZ: -10,
                scale: 2.4,
                backgroundColor: alpha(colorIn3, 1),
                transition: {
                  type: 'spring',
                  damping: 1,
                  stiffness: 100
                },
              }}
            >
              {letter === " " ? "\u00A0" : letter}
            </Box>
          )
        })}
    </motion.p>
  );
};


export default AnimateWavingTexts
